import React from 'react';
import styled from 'styled-components';
import Card from './Card';
import { ReactComponent as PlayCircle } from '../../../svgs/icons/play-circle.svg';

const Section = styled.section`
margin-bottom: 32px;
    .hostjane-main-SolidFeatures__heading{
        font-size: 35px;
        line-height: 1.3;
        font-style: italic;
        color: #0063d1;
        text-align: center;
        font-weight: 800;
				margin: 0px 0px 40px 0px;
				@media screen and (min-width: 1200px) {
					font-size: 35px;
				}
				@media(max-width: 576px){
					max-width: 320px;
					margin: 0 auto;
				}
		}


    .hostjane-main-SolidFeatures__container {
				max-width: 1200px;
				margin: 0 auto;
        display:flex;
        flex-wrap: wrap;
				justify-content: center;
				.hostjane-main-SolidFeatures__icon {
					height: 100px;
				}
    }

}

`;

const SolidFeatures = ({ features, heading, linkIcon, linkHref }) => {
	return (
		<Section id="server-hosting-features">
			<h2 className="hostjane-main-SolidFeatures__heading">{heading}</h2>
			<div className="hostjane-main-SolidFeatures__container">
				{features.map(val => (
					<Card
						icon={val.icon}
						title={val.title}
						description={val.description}
						link={val.link}
						linkIcon={linkIcon}
						linkHref={val.linkHref}
					/>
				))}
			</div>
		</Section>
	);
};

export default SolidFeatures;
