import React, { useMemo } from 'react';

import styled from 'styled-components';
import SectionWrapper from '../../SectionWrapper';
import FAQList from '../../FAQList';
import SiteContainer from '../../SiteContainer';

const Faq = styled.section`
	max-width: 1400px;
	padding: 0px 26px;
`;

export default function FAQs({ faqs, heading, id = undefined }) {
	const list = useMemo(
		() =>
			faqs.map(faq => ({
				title: faq.node.frontmatter.title,
				content: faq.node.body,
				id: faq.node.id,
			})),
		[faqs]
	);
	return (
		<Faq>
			<SectionWrapper id={id}>
				<SiteContainer>
					<FAQList list={list} heading={heading} />
				</SiteContainer>
			</SectionWrapper>
		</Faq>
	);
}
