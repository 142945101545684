import { ReactComponent as FeaturesIcon } from './icons/features.svg';
import { ReactComponent as FixIssueIcon } from './icons/fix-issue.svg';
import { ReactComponent as HelpIcon } from './icons/help.svg';
import { ReactComponent as LaunchVPSIcon } from './icons/launch-vps.svg';
import { ReactComponent as MyAccountIcon } from './icons/my-account.svg';
import { ReactComponent as PrivacyIcon } from './icons/privacy.svg';

export const buttons = [
	{
		internal: false,
		url: '#server-hosting-features', // don't change this
		icon: FeaturesIcon,
		label: 'Features',
	},
	{
		internal: true,
		url: '#compare-plans',
		icon: LaunchVPSIcon,
		label: 'Details',
	},
	{
		internal: false,
		url: '#hostjane-faqs', // don't change this
		icon: HelpIcon,
		label: 'FAQs',
	},
	{
		internal: false,
		url: 'https://cloud.hostjane.com/hosting/login',
		icon: MyAccountIcon,
		label: 'Sign In',
	},
	{
		internal: true,
		url: 'https://www.hostjane.com/marketplace/support-ticket', // don't change this
		icon: FixIssueIcon,
		label: 'Support',
	},
	{
		internal: true,
		url: 'https://www.hostjane.com/marketplace/support-ticket',
		icon: PrivacyIcon,
		label: 'Migrations',
	},
];
