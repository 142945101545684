import React, { useMemo } from 'react';

import SectionWrapper from '../../SectionWrapper';
import SiteContainer from '../../SiteContainer';
import GradientHeading from '../../GradientHeading';
import ScreenshotSlides from '../../ScreenshotSlides';

export default function Screenshots({
	heading,
	subHeading,
	screenshots,
	id = undefined,
}) {
	const slides = useMemo(() => {
		return screenshots.map(ss => {
			const { node } = ss;
			return {
				id: node.id,
				title: node.frontmatter.title,
				content: node.body,
				imageData: node.frontmatter.image.childImageSharp.fluid,
			};
		});
	}, [screenshots]);
	return (
		<SectionWrapper id={id}>
			<SiteContainer>
				<GradientHeading heading={heading} subHeading={subHeading} />
				<ScreenshotSlides slides={slides} />
			</SiteContainer>
		</SectionWrapper>
	);
}
