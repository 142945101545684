import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { MDXRenderer } from 'gatsby-plugin-mdx';

export const SlideTabsContainer = styled.div`
	padding: 0 6px;
	flex-shrink: 0;
`;

export const SlideImageContainer = styled.div`
	position: relative;
	width: 350px;
	margin-right: 30px;
`;

export const SlideImageAlignTop = styled.div`
	opacity: 0;
	animation: tab-content-out 400ms ease forwards;
	position: absolute;
	left: 0;
	width: 100%;
	max-height: 100%;
	z-index: -1;
	top: 0;
	transform: none;

	&.active {
		display: block;
		z-index: 1;
		position: static;
		animation: tab-content-in 400ms 400ms ease forwards;
	}

	@keyframes tab-content-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}

	@keyframes tab-content-out {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
`;

export const FullSlidesContainer = styled.div`
	display: flex;
	max-width: 1127px;
	margin: 0 auto;
	padding: 0 6px;
	display: flex;
	justify-content: center;

	${SlideTabsContainer} {
		width: 460px;
		flex-shrink: 1;
		margin-top: 30px;
	}
`;

export const SlideTabTrigger = styled.h4`
	margin: 0;
	color: #03363d;
	font-style: normal;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.2em;
`;

export const SlideTabDetail = styled.div`
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	transition: opacity 400ms, max-height 800ms, padding-top 200ms 400ms;
	p {
		color: #3d5154;
		line-height: 1.5em;
		margin: 0 0 1em 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
`;

export const SlideTabContainer = styled.div`
	position: relative;
	cursor: pointer;
	user-select: none;
	margin: 0;
	padding: 25px;
	border-radius: 3px;
	border-left: 3px solid transparent;
	transition: background-color 800ms, border-color 800ms, box-shadow 800ms,
		padding 400ms 400ms, margin 400ms 400ms;

	&:hover,
	&:focus {
		background: #fafafa;
		box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
		border-color: #d6eef2;
	}

	&.active {
		background: #fafafa;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15) !important;
		margin: 25px 0;
		transition: background-color 800ms 400ms, border-color 800ms 400ms,
			box-shadow 800ms 400ms, padding 400ms, margin 400ms;
		border-color: #d6eef2;
		&:first-child {
			margin-top: 0;
		}

		${SlideTabDetail} {
			opacity: 1;
			transition: opacity 800ms 200ms, max-height 800ms, padding-top 400ms;
			max-height: 500px;
			padding: 2em 0 0;
		}
	}
`;

export function SlideTab({ title, content, isActive, onClick }) {
	return (
		<SlideTabContainer
			className={classNames('hostjane-slidetab', { active: isActive })}
			onClick={e => {
				e.preventDefault();
				onClick();
			}}
			onKeyDown={e => {
				if (e.keyCode === 13 || e.keyCode === 32) {
					e.preventDefault();
					onClick();
				}
			}}
			tabIndex={0}
		>
			<div className="hostjane-slidetab__inner">
				<SlideTabTrigger className="hostjane-slidetab__trigger">
					{title}
				</SlideTabTrigger>
				<SlideTabDetail className="hostjane-slidetab__details">
					<MDXRenderer>{content}</MDXRenderer>
				</SlideTabDetail>
			</div>
		</SlideTabContainer>
	);
}
