import styled from 'styled-components';

const SectionWrapper = styled.section`
	margin: 0 0 80px 0;
	@media ${props => props.theme.breakpoints.desktopQuery} {
		margin-bottom: 160px;
	}
`;

export default SectionWrapper;
