const compareTableData = {
	heading: 'The Fine Print',
	subHeading:
		'All licenses are included in the cost of your server plan.',
	buttonText: 'Choose a Plan',
	shortBottomDescription: 'Ready to get started?',
	tableData: [
		{
			title: 'Website Transfers',
			tooltip:
				'Place an order to move your existing website to your new AWS server.',
		},
		{
			title: 'Bare-Metal Compute',
			tooltip:
				'Your own VPS virtualized on EC2 bare-metal compute.',
		},
		{
			title: 'Guaranteed Uptime',
			tooltip:
				'We manage servers in AWS\'s Virginia datacenter.',
		},
		{
			title: 'Fully-Managed',
			tooltip:
			'Linux experts to manage, maintain your VPS and MySQL database.',
		},
		{
			title: 'SSD Storage',
			tooltip:
				'Fits most small biz sites / apps based on data from BuiltWith.com.',
		},
		{
			title: 'Bandwidth',
			tooltip:
				'AWS\'s bandwidth is unmetered with high capacity and no overage charges.',
		},
		{
			title: '24/7 Support',
			tooltip:
				'365 days monitoring, patches and incident response.',
		},
		{
			title: 'Root Access',
			tooltip:
				'Generate your own SSH key during checkout or use our custom one.',
		},
		{
			title: 'Refund policy',
			tooltip:
				'We offer a 99.9% SLA to all customers. We don\'t offer a trial period.',
		},
	],
};

export default compareTableData;
