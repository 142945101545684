import React, { useRef, useEffect } from 'react';
import Img from 'gatsby-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Link } from 'gatsby';
import styled from 'styled-components';
import scrollToElement from 'scroll-to-element';

import { ReactComponent as MoreIcon } from './more.svg';
import SiteContainer from '../SiteContainer';
import SectionWrapper from '../SectionWrapper';

const ImageContainer = styled.div`
	height: auto;
	width: 100%;
	a {
		> div {
			z-index: -1;
		}
	}
`;

const ContentContainer = styled.div`
	position: relative;
	background: #fff;
	border-left: 8px solid transparent;
	border-image: linear-gradient(180deg, #008a00, #7de0a1 30%, #008381);
	border-image-slice: 0 0 0 1;
	padding: 24px 16px 0 21px;
	width: calc(100% - 27px);
	align-items: center;
	top: -32px;

	.hostjane-hosting-hero__title {
		margin: 0;
		color: #000000;
		font-weight: bold;
		line-height: 1.25;
		font-size: 45px;
		letter-spacing: -0.043rem;
	}
	.hostjane-hosting-hero__content {
		color: #0000000;
		font-weight: 400;
		margin: 0;
		line-height: 1.5;
		font-size: 25px;
		letter-spacing: -0.008rem;
		padding-top: 16px;

		p {
			&:first-child {
				margin-top: 0;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.hostjane-hosting-hero__link {
		text-decoration: none;
		position: relative;
		padding: 1rem 0.5rem;
		margin-left: -0.5rem;
		display: inline-block;
		line-height: 1.25;
		fill: currentColor;
		font-size: 25px;
		letter-spacing: -0.01875rem;
		color: #0064d2;
		padding-right: 32px;
		transition: transform 300ms;
		font-weight: 800;

		.hostjane-hosting-hero__linkarrow {
			margin-left: 0.25rem;
			margin-right: 0.25rem;
			transition: transform 0.3s;
			width: 0.625rem;
			height: 0.625rem;
		}

		&:hover,
		&:focus {
			color: #001e82;
			fill: currentColor;
			.hostjane-hosting-hero__linkarrow {
				transform: translateX(0.25rem);
			}
		}
	}
`;

const HostJaneHostingHeroWrapper = styled(SectionWrapper)`
	@media screen and (max-width: 768px) {
		margin-top: 10px;
	}
	${SiteContainer} {
		padding: 0;
		/* We put the padding back when the main nav bar is shown instead of mobile navbar */
		@media screen and (min-width: 1270px) {
			padding-left: 40px;
			padding-right: 40px;
		}
	}
`;

const MainHeroHeader = styled.header`
	padding: 0;
	margin: 0 auto;

	@media ${props => props.theme.breakpoints.tabletQuery} {
		${ContentContainer} {
			padding: 32px 16px 16px 48px;
			width: calc(100% - 56px);
			top: -64px;
		}
	}

	@media ${props => props.theme.breakpoints.desktopQuery} {
		display: flex;
		flex-direction: row-reverse;
		justify-content: center;
		align-items: center;

		${ImageContainer} {
			display: block;
			width: 60%;

			.gatsby-image-wrapper {
				height: 432px;
			}
		}

		${ContentContainer} {
			margin: 0 0 32px 0;
			padding: 16px 24px 8px 38px;
			border-left-width: 10px;
			position: unset;
			width: calc(40% + 64px);
			flex-shrink: 0;
			flex-grow: 1;
			min-height: 238px;
			max-height: 340px;
			height: 100%;
			margin-right: -64px;
			display: flex;
			flex-flow: column;
			justify-content: center;
			align-items: flex-start;

			.hostjane-hosting-hero__title {
				font-size: 45px;
				letter-spacing: -0.042rem;
				max-width: 450px;
			}

			.hostjane-hosting-hero__content {
				font-size: 25px;
				letter-spacing: -0.009rem;
			}

			.hostjane-hosting-hero__link {
				font-size: 25px;
				letter-spacing: -0.03125rem;
				font-weight: 800;
			}
		}
	}

	@media ${props => props.theme.breakpoints.desktopHdQuery} {
		/* max-width: ${props => props.theme.siteApp.maxWidth}px; */

		${ContentContainer} {
			min-height: 272px;
			.hostjane-hosting-hero__content {
				padding-right: 16px;
			}
		}
	}
`;

const ButtonsParent = styled.div`
	background: #fff;
	position: relative;
	margin-top: -40px;

	@media ${props => props.theme.breakpoints.desktopQuery} {
		margin-left: 32px;
		margin-right: 50px;
	}

	@media ${props => props.theme.breakpoints.desktopHdQuery} {
		max-width: 1136px;
		margin-left: auto;
		margin-right: auto;
	}
`;

const ButtonsContainer = styled.nav`
	padding: 32px 0 0 0;
	color: #414141;
	width: 100%;

	.hostjane-hosting-hero__buttonslist {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		padding: 0;
		margin: 0;
		list-style: none;
	}

	.hostjane-hosting-hero__buttonlistitem {
		list-style: none;
		display: flex;
		width: 50%;
		margin: 16px 0 0 0;
		padding: 0;

		&:nth-of-type(2),
		&:first-of-type {
			margin-top: 0;
		}
	}

	.hostjane-hosting-hero__buttonlistanchor {
		display: flex;
		flex-direction: column;
		color: #000000;
		width: 100%;
		text-decoration: none;

		&:hover,
		&:focus {
			color: #001e82;
			fill: currentColor;

			.hostjane-hosting-hero__buttonlistitemborder {
				color: #001e82;
				border-bottom: 2px solid #001e82;
				height: 0;
			}
		}
	}

	.hostjane-hosting-hero__buttonlistitemcontainer {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		width: 100%;
		padding-bottom: 6px;
		padding-top: 8px;
	}

	.hostjane-hosting-hero__buttonlistitemborder {
		isplay: flex;
		margin: 14px 12px 0;
		height: 2px;
	}
	.hostjane-hosting-hero__buttonicon {
		height: 32px;
		width: 32px;
		display: flex;
		margin: 0 0 10px;
	}
	.hostjane-hosting-hero__label {
		display: flex;
		justify-content: center;
		text-align: center;
		font-size: 18px;
		font-weight: 700;
		line-height: 22px;
		padding: 0 12px;
		width: 100%;
	}

	@media ${props => props.theme.breakpoints.tabletQuery} {
		.hostjane-hosting-hero__buttonlistitem {
			width: 33%;

			&:nth-of-type(3) {
				margin-top: 0;
			}

			&:nth-child(3n) {
				.hostjane-hosting-hero__buttonlistitemcontainer {
					border-right: 0;
				}
			}
		}

		.hostjane-hosting-hero__buttonlistitemcontainer {
			border-right: 1px solid #e8e8e8;
		}
	}

	@media ${props => props.theme.breakpoints.desktopQuery} {
		padding-top: 32px;
		padding-right: 24px;
		.hostjane-hosting-hero__buttonlistitem {
			width: 16.6666666666666667%;
			margin-top: 0;

			&:nth-child(3n) {
				.hostjane-hosting-hero__buttonlistitemcontainer {
					border-right: 1px solid #e8e8e8;
				}
			}

			&:last-child {
				.hostjane-hosting-hero__buttonlistitemcontainer {
					border-right: 0;
				}
			}
		}

		.hostjane-hosting-hero__buttonlistitemcontainer {
			border-right: 1px solid #e8e8e8;
		}
	}
`;

const jumpAnchorClassName = 'hostjane-hosting-hero__jumpanchor';
export default function HostJaneHostingHero({
	heading,
	content,
	imageFluidData,
	linkLabel,
	linkHash,
	buttons = [],
}) {
	const containerRef = useRef();
	useEffect(() => {
		const handler = event => {
			const { target } = event;
			// first figureout if this an anchor
			let anchor = null;
			if (target.tagName === 'A') {
				anchor = target;
			} else if (target.closest('a')) {
				anchor = target.closest('a');
			}
			if (anchor) {
				const href = anchor.getAttribute('href');
				if (href && href.match(/^#.+/)) {
					const scrollToNode = document.querySelector(href);
					if (scrollToNode) {
						if (typeof window !== 'undefined') {
							event.preventDefault();
							scrollToElement(scrollToNode, {
								offset: -20,
								align: 'top',
								ease: 'out-sine',
								duration: 700,
							});
						}
					}
				}
			}
		};

		const domNode = containerRef.current;

		if (domNode) {
			domNode.addEventListener('click', handler);
		}
		return () => {
			if (domNode) {
				domNode.removeEventListener('click', handler);
			}
		};
	}, [linkHash]);
	return (
		<HostJaneHostingHeroWrapper
			ref={containerRef}
			className="hostjane-hosting-hero"
		>
			<SiteContainer>
				<MainHeroHeader className="hostjane-hosting-hero__header">
					<ImageContainer>
						<a href={linkHash} className={jumpAnchorClassName}>
							<Img
								fluid={imageFluidData}
								backgroundColor="#E3E3E3"
								alt=""
							/>
						</a>
					</ImageContainer>
					<ContentContainer>
						<h1 className="hostjane-hosting-hero__title">
							{heading}
						</h1>
						<div className="hostjane-hosting-hero__content">
							<MDXRenderer>{content}</MDXRenderer>
						</div>
						<a
							href={linkHash}
							className={`hostjane-hosting-hero__link ${jumpAnchorClassName}`}
						>
							{linkLabel}{' '}
							<MoreIcon className="hostjane-hosting-hero__linkarrow" />
						</a>
					</ContentContainer>
				</MainHeroHeader>
				<ButtonsParent>
					<ButtonsContainer>
						<ul className="hostjane-hosting-hero__buttonslist">
							{buttons.map(button => {
								const innerChildren = (
									<>
										<button.icon className="hostjane-hosting-hero__buttonicon" />
										<span className="hostjane-hosting-hero__label">
											{button.label}
										</span>
									</>
								);
								let children;
								if (button.internal) {
									children = (
										<Link
											to={button.url}
											className="hostjane-hosting-hero__buttonlistanchor"
										>
											<div className="hostjane-hosting-hero__buttonlistitemcontainer">
												{innerChildren}
											</div>
											<div className="hostjane-hosting-hero__buttonlistitemborder" />
										</Link>
									);
								} else {
									children = (
										<a
											key={`${button.url}-${button.label}`}
											href={button.url}
											className="hostjane-hosting-hero__buttonlistanchor"
										>
											<div className="hostjane-hosting-hero__buttonlistitemcontainer">
												{innerChildren}
											</div>
											<div className="hostjane-hosting-hero__buttonlistitemborder" />
										</a>
									);
								}
								return (
									<li
										className="hostjane-hosting-hero__buttonlistitem"
										key={`${button.url}-${button.label}`}
									>
										{children}
									</li>
								);
							})}
						</ul>
					</ButtonsContainer>
				</ButtonsParent>
			</SiteContainer>
		</HostJaneHostingHeroWrapper>
	);
}
