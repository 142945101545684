import React from 'react';
import styled from 'styled-components';

import SectionWrapper from '../../SectionWrapper';
import GradientHeading from '../../GradientHeading';
import VPSBoxes from '../../VPSBoxes';
import SiteContainer from '../../SiteContainer';
import Alert from '../../Alert';

const HostingAlertWrapper = styled.div`
	margin: 20px 0 0 0;
	padding: 0 36px;
	@media ${props => props.theme.breakpoints.desktopQuery} {
		padding: 0 46px;
		margin-top: 40px;
	}
`;

export default function ServerPlan({
	heading,
	subHeading,
	id,
	boxes,
	icon,
	message,
	title,
	isHosting,
}) {
	return (
		<SectionWrapper id={id}>
			<SiteContainer>
				<GradientHeading heading={heading} subHeading={subHeading} />
				<VPSBoxes boxes={boxes} />
				{message !== null && message !== '' ? (
					<HostingAlertWrapper>
						<Alert
							isHosting
							icon={icon}
							message={message}
							title={title}
						/>
					</HostingAlertWrapper>
				) : null}
			</SiteContainer>
		</SectionWrapper>
	);
}
