import React, { useState } from 'react';
import classNames from 'classnames';
import Img from 'gatsby-image';

import {
	FullSlidesContainer,
	SlideImageContainer,
	SlideTabsContainer,
	SlideTab,
	SlideImageAlignTop,
} from './common';

export default function LargeSlides({ slides = [] }) {
	const [activeSlideId, setActiveSlideId] = useState(slides[0].id);
	return (
		<FullSlidesContainer>
			<SlideImageContainer>
				{slides.map(slide => (
					<SlideImageAlignTop
						className={classNames({
							active: slide.id === activeSlideId,
						})}
						key={slide.id}
					>
						<Img alt="" fluid={slide.imageData} />
					</SlideImageAlignTop>
				))}
			</SlideImageContainer>
			<SlideTabsContainer>
				{slides.map(slide => (
					<SlideTab
						key={slide.id}
						title={slide.title}
						content={slide.content}
						isActive={activeSlideId === slide.id}
						onClick={() => {
							setActiveSlideId(slide.id);
						}}
					/>
				))}
			</SlideTabsContainer>
		</FullSlidesContainer>
	);
}
