import React from 'react';
import styled from 'styled-components';

const GradientHeadingContainer = styled.div`
	margin: 0 0 ${props => props.theme.gutter.small}px 0;
	@media ${props => props.theme.breakpoints.desktopQuery} {
		margin-bottom: ${props => props.theme.gutter.large}px;
	}
	.hostjane-gradient-heading__heading {
		font-size: 60px;
		line-height: 1.1;
		margin: 0 auto;
		padding: 0;
		max-width: 90%;
		text-align: center;
		color: #fff; /** fallback color */
		background: linear-gradient(
			to right,
			rgb(243, 5, 109) 9.38%,
			rgb(0, 105, 255) 88.54%
		);
		background-clip: text;
		-webkit-background-clip: text;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
		font-weight: 800;
		letter-spacing: -0.01em;
		@media screen and (min-width: 950px) {
			font-size: 60px;
		}
	}

	.hostjane-gradient-heading__subheading {
		margin: 0;
		margin-top: ${props => props.theme.gutter.small}px;
		padding: 0;

		color: #000000;
		line-height: 1.8;
		font-size: 24px;
		font-weight: 400;
		text-align: center;

		@media ${props => props.theme.breakpoints.tabletQuery} {
			font-size: 22px;
			line-height: 1.636;
			max-width: 780px;
			margin-left: auto;
			margin-right: auto;
		}
	}
`;

export default function GradientHeading({
	heading,
	subHeading,
	id = undefined,
}) {
	return (
		<GradientHeadingContainer id={id} className="hostjane-gradient-heading">
			<h2 className="hostjane-gradient-heading__heading">{heading}</h2>
			{subHeading ? (
				<div className="hostjane-gradient-heading__subheading">
					{subHeading}
				</div>
			) : null}
		</GradientHeadingContainer>
	);
}
