import React from 'react';
import styled from 'styled-components';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const FAQContainer = styled.div`
	padding: 0 34px;

	@media ${props => props.theme.breakpoints.desktopQuery} {
		display: flex;
		flex-flow: row nowrap;
		align-items: flex-start;
		justify-content: space-evenly;
	}
`;

const FAQHeading = styled.h2`
	font-weight: 800;
	font-size: 35px;
	max-width: 500px;
	letter-spacing: -0.9px;
	line-height: 1.44;
	color: rgb(3, 27, 78);
	margin: 0 0 30px 0;
	@media ${props => props.theme.breakpoints.desktopQuery} {
		font-size: 37px;
		width: 200px;
		flex: 0 0 200px;
	}
`;

const FAQContentsContainer = styled.div`
	max-width: 734px;
	.hostjane-faq-content__article {
		margin: 0 0 20px 0;
	}
	.hostjane-faq-content__heading {
		font-size: 28px;
		color: rgb(3, 27, 78);
		font-weight: 800;
		margin: 0 0 14px 0;
		line-height: 1.44;
	}

	.hostjane-faq-content__body {
		font-size: 18px;
		line-height: 1.8;
		font-weight: 400;
		color: #000000;
		p {
			margin: 0 0 14px 0;
			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			font-weight: 500;
			color: rgba(0, 105, 255);
			&:hover {
				color: rgba(0, 105, 255);
				text-decoration: none;
			}
		}
	}

	@media ${props => props.theme.breakpoints.desktopQuery} {
		margin-top: 15px;

		.hostjane-faq-content__article {
			margin: 0 0 30px 0;
		}

		.hostjane-faq-content__heading {
			font-size: 28px;
			margin-bottom: 20px;
		}
		.hostjane-faq-content__body {
			font-size: 18px;
			p {
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
`;

export default function FAQList({ list = [], heading }) {
	return (
		<FAQContainer>
			<FAQHeading>{heading}</FAQHeading>
			<FAQContentsContainer>
				{list.map(faq => (
					<article
						className="hostjane-faq-content__article"
						key={faq.id}
					>
						<h4 className="hostjane-faq-content__heading">
							{faq.title}
						</h4>
						<div className="hostjane-faq-content__body">
							<MDXRenderer>{faq.content}</MDXRenderer>
						</div>
					</article>
				))}
			</FAQContentsContainer>
		</FAQContainer>
	);
}
