import React from 'react';
// import { MdPlayCircleFilled } from "react-icons/md";
import styled from 'styled-components';
import { Link } from 'gatsby';

const CardContainer = styled.div`
max-width: 40%;
margin:0 30px 48px;
@media only screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
	max-width: 100%
}
.hostjane-main-card__subContainer{
        display:flex;
        margin:0 30px 48px;
        @media only screen and (max-width: 768px) {
            flex-direction:column;
            margin:0 10px 48px;
        }

        .hostjane-main-card__icon {
            margin: 20px 20px 0 0;
				}
				.hostjane-main-SolidFeatures__link-icon {
					fill: rgb(252, 106, 101);
					height: 16px;
					width: 16px;
					margin-top: 2px;
					margin-left: 5px;
				}
        h4 {
            font-size: 24px;
            font-style: italic;
            font-weight: 600;
            color: rgb(21, 26, 45);
						margin-bottom: 8px;
						line-height: 1.5
        }

        p {
            color: rgb(21, 26, 45);
						margin-bottom: 32px;
						line-height: 1.8
        }

        a {
            color: rgb(252, 106, 101);
            font-weight: 500;
            transition: color 0.15s linear 0s;
            text-decoration: none;
            display: flex;
            align-items: center;
            cursor:pointer;
            :hover{
                text-decoration: underline;
						}
						line-height: 1.5
       }
    }
}`;

const Card = ({ icon, title, description, link, linkIcon, linkHref }) => {
	return (
		<CardContainer>
			<div className="hostjane-main-card__subContainer">
				<div className="hostjane-main-card__icon">{icon}</div>
				<div>
					<h4>{title}</h4>
					<p>{description}</p>
					{link && (
						<a href={linkHref} target="_blank" rel="noreferrer">
							{link} {linkIcon}
						</a>
					)}
				</div>
			</div>
		</CardContainer>
	);
};

export default Card;
