import React, { useState } from 'react';
import { SlideTabsContainer, SlideTab } from './common';

export default function SmallSlides({ slides = [] }) {
	const [activeSlides, setActiveSlides] = useState([slides[0].id]);
	return (
		<SlideTabsContainer>
			{slides.map(slide => (
				<SlideTab
					key={slide.id}
					title={slide.title}
					content={slide.content}
					isActive={activeSlides.includes(slide.id)}
					onClick={() => {
						setActiveSlides(currentSlides => {
							if (currentSlides.includes(slide.id)) {
								return currentSlides.filter(
									id => id !== slide.id
								);
							}
							return [...currentSlides, slide.id];
						});
					}}
				/>
			))}
		</SlideTabsContainer>
	);
}
