/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { ReactComponent as CheckIcon } from './check.svg';

const BoxContainer = styled.div`
	position: relative;
	margin: 0 0 20px;
	&:last-child {
		margin-bottom: 0;
	}
	color: #111;
	border: 1px solid #d4dbe0;

	p {
		margin: 0;
	}

	.hostjane-vpsbox__anchor {
		display: block;
		padding: 48px 40px;
		color: #111;
		height: 100%;
	}

	.hostjane-vpsbox__icons {
		position: absolute;
		display: flex;
		flex-flow: row nowrap;
		right: 15px;
		top: 20px;

		.hostjane-vpsbox__smallscicons {
			display: none;
			flex-flow: row nowrap;
			@media screen and (min-width: 1024px) and (max-width: 1269px) {
				display: flex;
			}
		}
		.hostjane-vpsbox__largescicons {
			flex-flow: row nowrap;
			display: flex;
			@media screen and (min-width: 1024px) and (max-width: 1269px) {
				display: none;
			}
		}
	}
	.hostjane-vpsbox__icon {
		height: 120px;
		width: auto;
		color: #007bfc;
		fill: currentColor;
		margin: 0 5px;
	}

	.hostjane-vpsbox__anchor {
		color: #111;
		text-decoration: none;
		&:hover,
		&:focus {
			color: #111;
		}
	}

	.hostjane-vpsbox__planlabel {
		font-size: 24px;
		font-weight: 800;
		margin: 0 0 15px 0;
		line-height: 1.5;
	}

	.hostjane-vpsbox__price {
		font-size: 16px;
		line-height: 1.5;
		margin-bottom: 1rem;
		.hostjane-vpsbox__low {
			font-size: 16px;
		}
		.hostjane-vpsbox__amount {
			font-size: 2.02729rem;
			line-height: 40px;
			font-weight: 700;
		}
		.hostjane-vpsbox__sale {
			margin: 2px 0 0 0;
			font-weight: 700;
			background-color: #ffeea9;
		}
		.hostjane-vpsbox__saletm {
			font-size: 16px;
			font-weight: 400;
			color: #767676;
		}
	}

	.hostjane-vpsbox__button {
		transition: background-color 0.15s ease-in-out,
			border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		padding: 0.625rem 1.5rem;
		font-size: 1rem;
		line-height: 1.5rem;
		border-width: 0;
		display: block;
		min-height: 2.75rem;
		text-align: center;
		vertical-align: middle;
		user-select: none;
		font-weight: 700;
		text-decoration: none;
		text-transform: none;
		white-space: normal;
		cursor: pointer;
		outline: 0;
		border: 0.125rem solid #111;
		color: #111;
		background: #fff;
		margin: 0 0 20px 0;
	}

	.hostjane-vpsbox__features {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			list-style: none;
			padding: 7px 0;
			display: flex;
			align-items: flex-start;

			.hostjane-vpsbox__featurecheckmark {
				margin: 0.25em 10px 0 0;
				color: #00a4a6;
				height: 0.75em;
				width: 0.75em;
			}
		}
	}

	.hostjane-vpsbox__footer {
		margin: 20px 0 0 0;
	}

	.hostjane-vpsbox__anchor:hover,
	.hostjane-vpsbox__anchor:active {
		.hostjane-vpsbox__button {
			color: #fff;
			background-color: #00a4a6;
			border-color: #00a4a6;
		}
	}
`;

const BoxesContainer = styled.div`
	padding: 0 36px;
	@media ${props => props.theme.breakpoints.desktopQuery} {
		padding: 0 46px;
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;

		${BoxContainer} {
			flex: 1 1 400px;
			max-width: 500px;
			margin: 0;
			border-right: 0 none;

			&:last-child {
				border-right: 1px solid #d4dbe0;
			}
		}
	}
`;

function Box(props) {
	const children = (
		<>
			<div className="hostjane-vpsbox__icons">
				{props.smallIcons && props.smallIcons.length ? (
					<>
						<span className="hostjane-vpsbox__smallscicons">
							{props.smallIcons.map((Icon, iKey) => (
								<Icon
									className="hostjane-vpsbox__icon"
									key={iKey}
								/>
							))}
						</span>
						<span className="hostjane-vpsbox__largescicons">
							{props.icons.map((Icon, iKey) => (
								<Icon
									className="hostjane-vpsbox__icon"
									key={iKey}
								/>
							))}
						</span>
					</>
				) : (
					props.icons.map((Icon, iKey) => (
						<Icon className="hostjane-vpsbox__icon" key={iKey} />
					))
				)}
			</div>
			<h4 className="hostjane-vpsbox__planlabel">{props.planLabel}</h4>
			<div className="hostjane-vpsbox__planprice">
				<p className="hostjane-vpsbox__price">
					<span className="hostjane-vpsbox__low">
						{props.planSlogan}
					</span>
					<br />
					<span className="hostjane-vpsbox__amount">
						{props.amount}
					</span>{' '}
					/
					<span className="hostjane-vpsbox__duration">
						{props.duration}
					</span>
					<br />
					<span className="hostjane-vpsbox__sale">{props.sale}</span>
					<br />
					<span className="hostjane-vpsbox__saletm">
						{props.saleTm}
					</span>
				</p>
			</div>
			<div className="hostjane-vpsbox__button">{props.linkLabel}</div>
			<ul className="hostjane-vpsbox__features">
				{props.features.map((f, k) => (
					<li key={k}>
						<CheckIcon className="hostjane-vpsbox__featurecheckmark" />
						<span className="hostjane-vpsbox__featurelabel">
							<strong>{f.count}</strong> {f.label}
						</span>
					</li>
				))}
			</ul>
			{props.footer ? (
				<div className="hostjane-vpsbox__footer">{props.footer}</div>
			) : null}
		</>
	);
	return (
		<BoxContainer className="hostjane-vpsbox__card">
			{props.linkInternal ? (
				<Link className="hostjane-vpsbox__anchor" to={props.link}>
					{children}
				</Link>
			) : (
				<a href={props.link} className="hostjane-vpsbox__anchor">
					{children}
				</a>
			)}
		</BoxContainer>
	);
}

export default function VPSBoxes({ boxes = [] }) {
	return (
		<BoxesContainer className="hostjane-vpsbox">
			{boxes.map((box, i) => (
				<Box {...box} key={i} />
			))}
		</BoxesContainer>
	);
}
