import styled from 'styled-components';

const ButtonLink = styled.a`
	background-size: 100% 100%;
	box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.lightOne},
		0 0 6px 0 ${props => props.theme.neutrals.lightOne};
	border-radius: 4px;
	display: flex;
	height: 50px;
	width: 100%;
	padding: 0 60px;
	align-items: center;
	justify-content: center;
	transition: all 200ms ${props => props.theme.effects.easeOutSine},
		background-size 300ms ${props => props.theme.effects.easeOutSine};
	color: ${props => props.theme.neutrals.lightTwo};
	font-weight: bold;
	background-image: linear-gradient(
		90deg,
		${props => props.theme.primary.darkest} 0%,
		${props => props.theme.primary.lightest} 100%
	);
	${props =>
		props.backgroundBlue &&
		`
		background-image: linear-gradient(
			90deg,
			rgb(26, 115, 232) 0%,
			rgb(9 56 226) 100%
		);
		border-color: #1e88e5;
`}
	&:hover {
		background-size: 200% 100%;
		box-shadow: 0 1px 2px 0 ${props => props.theme.neutrals.secondaryText},
			0 0 6px 0 ${props => props.theme.neutrals.secondaryText};
		border-radius: 4px;
		color: ${props => props.theme.neutrals.lightTwo};
		text-decoration: none;
	}

	&:active {
		box-shadow: 0 1px 2px 0 transparent, 0 0 6px 0 transparent;
	}
`;

export default ButtonLink;
