import React from 'react';
import styled from 'styled-components';

import SmallSlides from './SmallSlides';
import LargeSlides from './LargeSlides';

const SlidesContainer = styled.div`
	margin-top: 40px;
	.hostjane-slides__small {
		display: block;
	}
	.hostjane-slides__large {
		display: none;
	}

	@media ${props => props.theme.breakpoints.desktopQuery} {
		margin-top: 80px;
		.hostjane-slides__small {
			display: none;
		}
		.hostjane-slides__large {
			display: block;
		}
	}
`;

export default function ScreenshotSlides({ slides }) {
	return (
		<SlidesContainer className="hostjane-slides">
			<div className="hostjane-slides__small">
				<SmallSlides slides={slides} />
			</div>
			<div className="hostjane-slides__large">
				<LargeSlides slides={slides} />
			</div>
		</SlidesContainer>
	);
}
